import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useParams } from "react-router-dom";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";
import { PATH_AUTH } from "src/routes/paths";
import imagetwo from "src/images/imagetwo.png";
import imagethree from "src/images/imgthree.png";
import imagefour from "src/images/image four.png";
// import "./css/bootstrap.min.css";
import { Link } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import "./css/style.css";

import Apiwhite from "src/images/apiwhite.png";
import IntroIMG from "src/images/introImg.png";
import imageone from "src/images/imageone.png";
import LogoRegister from "src/layouts/shared/navbar/asssets/sparissimoapi.png";
import RegisterForm from "src/sections/auth/register/RegisterForm";
import apivideo from "src/video/api.mp4";
const Index = () => {
  const { user } = useAuth();
  const { uname } = useParams();
  const isLoggedIn = !user;
  const smUp = useResponsive("up", "sm");
  const { palette } = useTheme();
  const mdUp = useResponsive("up", "md");
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>apibusiness.online</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="fb:page_id" content="" />
        <meta property="og:image" content="" />
        <meta property="og:description" content="" />
        <meta property="og:title" content="" />
        <meta itemprop="name" content="" />
        <meta itemprop="description" content="" />
        <meta itemprop="image" content="" />
        <link rel="icon" type="image/x-icon" href="images/favicon.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/css/bootstrap.min.css"
          integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
          crossorigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css"
          rel="stylesheet"
        />
        <script
          src="https://code.jquery.com/jquery-3.6.1.min.js"
          integrity="sha256-o88AwQnZB+VDvE9tvIXrMQaPlFFSUTR+nldQm1LuPXQ="
          crossorigin="anonymous"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js"
          integrity="sha512-Eak/29OTpb36LLo2r47IpVzPBLXnAMPAVypbSZiZ4Qkf8p/7S/XRG5xp7OKWPPYfJT6metI+IORkR5G8F900+g=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.1/js/bootstrap.min.js"
          integrity="sha512-UR25UO94eTnCVwjbXozyeVd6ZqpaAE9naiEUBK/A+QDbfSTQFhPGj5lOR6d8tsgbBk84Ggb5A3EkjsOgPRPcKA=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        ></script>
      </Helmet>
      <body>
        <div className="banner">
          <nav
            className="navbar container navbar-expand-lg navbar-light dxstoxLNav"
            style={{ zIndex: 2 }}
          >
            {/* <a className="navbar-brand" href="#">
              <img src={Logoapi} style={{ width: "90px" }} />
            </a> */}

            <div className="" id="navbarSupportedContent">
              <img className="LogoRegister" src={LogoRegister} />

              <ul className="customeNavbar-nav">
                {isLoggedIn ? (
                  <>
                    {/* <li>
                      <Link to="/auth/register" className="nav-link">
                        Register
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/auth/login" className="nav-link loginBtn">
                        Login
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/dashboard/app/business"
                        className="nav-link loginBtn"
                      >
                        Dashboard
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </nav>

          {/* <img className="bannerImgg" src={banner} /> */}
          <div> {/* <RegisterForm /> */}</div>
        </div>
        {/* ====================================================================================== */}

        <section className="bannerContainer">
          <div className="container bannerAlgmnt">
            <div className="row alignceneter">
              <div className="col-lg-7 col-md-6">
                <div className="col-lg-8 col-sm-12">
                  <h1 className="titlemain">
                    <span className="primryColr">Unique</span> chance in &nbsp;
                    <span className="primryColr">the world!</span>
                  </h1>

                  <h4 className="titlemainSub">
                    Thanks to our many years of experience in online marketing,
                    we give you the chance to build up your automatic, passive
                    income now!
                  </h4>
                </div>
              </div>

              <div className="col-lg-5 col-md-6">
                <div className="rgstrFormBox">
                  <RegisterForm />
                  <div className="clearfix"></div>
                  {smUp && (
                    <Typography
                      variant="body2"
                      sx={{ mt: { md: -2 } }}
                      style={{
                        marginTop: "5px",
                        fontSize: "13px",
                      }}
                    >
                      Already have an account&nbsp;? &nbsp;&nbsp;
                      <Link
                        className="Linktext"
                        component={RouterLink}
                        to={PATH_AUTH.login}
                      >
                        Login
                      </Link>
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ====================================================================================== */}
        <section className="bannersection">
          <div className="container">
            <div className="BoxSectionamount">
              <Stack
                sx={{
                  marginTop: "1rem",
                  marginBottom: "1.5rem",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: "150",
                }}
                spacing={1}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 1,
                    columnGap: 1,
                    gridTemplateColumns: {
                      md: "repeat(4,1fr)",
                    },
                  }}
                >
                  <Stack>
                    <h2>25K</h2>
                    <p>Members from europe</p>
                  </Stack>

                  <Stack>
                    <h2>#18</h2>
                    <p>Years of online marketing experience</p>
                  </Stack>
                  <Stack>
                    <h2>2500</h2>
                    <p>company affiliated</p>
                  </Stack>
                  <Stack>
                    <h2>50 USD</h2>
                    <p>One time fee</p>
                  </Stack>
                </Box>
              </Stack>
            </div>
          </div>
        </section>

        <section className="bannersection">
          <div className="container">
            <div className="row rowreverseMobile alignceneter">
              <div className="col-md-8 col-lg-7">
                <div
                  className="videoSection"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  <img
                    src={IntroIMG}
                    width="610"
                    height="564"
                    alt="Arbitmine"
                  />
                  <div className="videoBtnBox">
                    <i class="ri-play-line"></i>
                  </div>
                </div>
                <div className="modal" id="myModal">
                  <div className="modal-dialog videoPopup">
                    <div className="modal-content">
                      <b class="close" data-dismiss="modal">
                        <i class="ri-close-fill"></i>
                      </b>

                      <div className="modal-body">
                        <video width="100%" controls>
                          <source
                            src="https://server.apibusiness.online/api.mp4"
                            type="video/mp4"
                          />
                          <source
                            src="https://server.apibusiness.online/api.mp4"
                            type="video/ogg"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 wow fadeInLeft">
                <h2 className="titlemain">
                  <span className="primryColr">We help you </span> to
                  build&nbsp;
                  <span className="primryColr">your business!</span>
                </h2>

                <p className="bannrcaptn">
                  Our company is dedicated to helping every business and
                  individual to generate passive income with a customized online
                  marketing plan and optimization.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bannersectionone">
          <div className="container">
            <div className="row rowreverseMobile alignceneter">
              <div className="col-lg-6 col-md-6 wow fadeInLeft">
                <h2 className="titlemain">
                  <span className="primryColr"> No empty </span> promises
                </h2>

                <p className="textCaption">
                  With us you can see in real time how you generate income ! You
                  have the opportunity to be there right from the start !
                </p>

                <a
                  style={{
                    textDecoration: "none",
                    width: "165px",
                    marginBottom: "5px",
                    marginTop: "17px",
                  }}
                  class="button-21"
                  href={`/${uname}`}
                >
                  Register Now
                </a>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="imageOne">
                  <img
                    src={imageone}
                    width="500"
                    height="300"
                    alt="Arbitmine"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bannersectionone">
          <div className="container">
            <div className="row rowreverseMobile alignceneter">
              <div className="col-lg-6 col-md-6">
                <div className="imageOne">
                  <img
                    src={imagetwo}
                    width="500"
                    height="300"
                    alt="Arbitmine"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 wow fadeInLeft">
                <h2 className="titlemain">
                  <span className="primryColr"> Experts from </span> the online
                  marketing industry{" "}
                </h2>

                <p className="textCaption">
                  We are at your side with our many years of online marketing
                  experience.You can sit back and watch us work for you in the
                  background!
                </p>

                <a
                  style={{
                    textDecoration: "none",
                    width: "165px",
                    marginBottom: "5px",
                    marginTop: "17px",
                  }}
                  class="button-21"
                  href={`/${uname}`}
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="bannersectionone">
          <div className="container">
            <div className="row rowreverseMobile alignceneter">
              <div className="col-lg-6 col-md-6 wow fadeInLeft">
                <h2 className="titlemain">
                  <span className="primryColr"> Automatic </span> passive income
                </h2>

                <p className="textCaption">
                  Through our existing company, you generate income every day
                  through purchases,takeaway and delivery!
                </p>

                <a
                  style={{
                    textDecoration: "none",
                    width: "165px",
                    marginBottom: "5px",
                    marginTop: "17px",
                  }}
                  class="button-21"
                  href={`/${uname}`}
                >
                  Register Now
                </a>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="imageOne">
                  <img
                    src={imagethree}
                    width="500"
                    height="300"
                    alt="Arbitmine"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bannersectionone">
          <div className="container">
            <div className="row rowreverseMobile alignceneter">
              <div className="col-lg-6 col-md-6">
                <div className="imageOne">
                  <img
                    src={imagefour}
                    width="500"
                    height="300"
                    alt="Arbitmine"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 wow fadeInLeft">
                <h2 className="titlemain">
                  <span className="primryColr">No more </span>investment losses{" "}
                </h2>

                <p className="textCaption">
                  With us, you can't lose your investment, guaranteed, because
                  we're involved in our customers' daily online sales!
                </p>

                <a
                  style={{
                    textDecoration: "none",
                    width: "165px",
                    marginBottom: "5px",
                    marginTop: "17px",
                  }}
                  class="button-21"
                  href={`/${uname}`}
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* ============================================================ */}
        <section className="sectionpadding">
          <div className="container">
            <div className="row  alignceneter">
              <div className="col-lg-6 col-md-5">
                <h2 className="titlemain">
                  <span className="primryColr">We're </span>Accomplished In{" "}
                </h2>

                {/* <h4 className="titlemainSub">Loaram Ipsumis a dummy text</h4> */}
                <p className="bannrcaptn">
                  With our selection of expert-recommended passive income
                  opportunities, we can help you unlock your financial potential
                  and make your financial dreams a reality. Our top-notch
                  passive income businesses offer a variety of strategies that
                  allow you to make money while you sleep.
                </p>
              </div>

              <div className="col-md-7 col-lg-6">
                <div className="speciAnimtn">
                  <div className="speciAnimtnBuble1"></div>
                  <div className="speciAnimtnBuble2"></div>
                  <div className="speciAnimtnBuble3"></div>
                </div>
                <ul class="speci">
                  <li>
                    <div class="iconBox">
                      <i class="ri-check-line"></i>
                    </div>
                    <div class="CntntBox">
                      <h2>Automatic passive income</h2>
                      <p>
                        Achieve automatic passive income daily from purchases,
                        take away, and delivery with our company.!
                      </p>
                    </div>
                  </li>

                  <li>
                    <div class="iconBox">
                      <i class="ri-check-line"></i>
                    </div>
                    <div class="CntntBox">
                      <h2>No more disappointments</h2>
                      <p>
                        Invest with confidence knowing there will be no more
                        disappointments!
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="iconBox">
                      <i class="ri-check-line"></i>
                    </div>
                    <div class="CntntBox">
                      <h2>Limited number of participants</h2>
                      <p>
                        Secure your spot in the limited participation webinar by
                        registering now and start right away tomorrow!
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="iconBox">
                      <i class="ri-check-line"></i>
                    </div>
                    <div class="CntntBox">
                      <h2>No empty promises</h2>
                      <p>
                        Real-time earnings tracking available with no more
                        promises!
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* ============================================================ */}

        <section className="bannersection">
          <div className="container">
            <div className="LognBtnFooterBox">
              <h3> Build Your Passive Income Portfolio Today</h3>
              <p>
                Maximize passive income with our top-notch businesses. From real
                estate to affiliate marketing, diversify and secure your
                financial future. Login or register for more details. Start
                building your portfolio today.!
              </p>

              <a class="loginBtnFooter" href="/auth/login">
                Login
              </a>
            </div>
          </div>
        </section>
        {/* ============================================================ */}

        <footer className="footersection">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto text-center">
                <img className="Logofooter" src={Apiwhite} />
              </div>
              <div className="col-md-5 mx-auto text-center">
                <Stack
                  sx={{
                    marginTop: "1rem",
                    marginBottom: "1.5rem",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "258px",
                  }}
                  spacing={1}
                >
                  <Box
                    sx={{
                      display: "grid",
                      rowGap: 1,
                      columnGap: 1,
                      gridTemplateColumns: {
                        md: "repeat(4,1fr)",
                      },
                    }}
                  >
                    <a
                      href="https://www.facebook.com/apiprogramm"
                      target="_blank"
                      style={{ cursor: "pointer", padding: "0.5rem" }}
                    >
                      <Tooltip title="Facebook">
                        <Stack alignItems="center" spacing={1}>
                          <Iconify
                            icon="logos:facebook"
                            sx={{
                              fontSize: "1rem",
                              color: palette.primary.main,
                            }}
                          />
                        </Stack>
                      </Tooltip>
                    </a>

                    <a
                      href="https://www.facebook.com/apiprogramm"
                      target="_blank"
                      style={{ cursor: "pointer", padding: "0.5rem" }}
                    >
                      <Tooltip title="Instagram">
                        <Stack alignItems="center" spacing={1}>
                          <Iconify
                            icon="skill-icons:instagram"
                            sx={{
                              fontSize: "1rem",
                              color: palette.primary.main,
                            }}
                          />
                        </Stack>
                      </Tooltip>
                    </a>

                    <a
                      href="https://www.tiktok.com/@apiprogramm?_t=8ZaT76WzvZG&_r=1"
                      target="_blank"
                      style={{ cursor: "pointer", padding: "0.5rem" }}
                    >
                      <Tooltip title="Tiktok">
                        <Stack alignItems="center" spacing={1}>
                          <Iconify
                            icon="logos:tiktok-icon"
                            sx={{
                              fontSize: "1rem",
                              color: palette.primary.main,
                            }}
                          />
                        </Stack>
                      </Tooltip>
                    </a>
                    <Typography>imPrint:</Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Stack sx={{ marginTop: "-12px" }}>
                      {" "}
                      <p>
                        {/* imPrint :&nbsp; */}
                        <a
                          href="https://www.sparissimo.com/impressum"
                          target="_blank"
                        >
                          https://www.sparissimo.com/impressum
                        </a>
                      </p>{" "}
                    </Stack>
                  </Box>
                </Stack>
              </div>
            </div>
          </div>
        </footer>
      </body>
    </>
  );
};

export default Index;
